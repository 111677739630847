<template>

  <div>
    <div class=" mobile-list-task" v-for="(data,dataindex) in datas" :key="dataindex" @click="click(data)">
      <div class="mobile-list-row" v-for="(column,columindex) in columns" :key="columindex">
        <div class="fill-row flex-row  margin-top-10" style="height:20px;line-height:20px;" v-if="column.type=='name_key_rightkey'">
          <div class="fill-row3 text-left  margin-left-10">
            <div :style="column.nameStyle">
              {{ column.name }}
            </div>
          </div>
          <div class="fill-row3 text-left ">
            <div :style="column.keyStyle">
              {{ data[column.key] }}
            </div>
          </div>
          <div class="fill-row3 text-right margin-right-10">
            <div :style="column.rightkeyStyle">
              {{data[column.rightkey]}}
            </div>
          </div>
          <div>
          </div>
        </div>
        <div class="fill-row flex-row  margin-top-10" style="height:20px;line-height:20px;" v-if="column.type=='key_rightkey'">
          <div class="fill-row3 text-left margin-left-10">
            <div :style="column.keyStyle">
              {{ data[column.key] }}
            </div>
          </div>
          <div class="fill-row3 text-left">

          </div>
          <div class="fill-row3 text-right margin-right-10">
            <div :style="column.rightkeyStyle">
              {{data[column.rightkey]}}
            </div>
          </div>
        </div>
        <div class="fill-row flex-row  margin-top-10" style="height:20px;line-height:20px;" v-if="column.type=='name_key'">
          <div class="fill-row3 text-left margin-left-10">
            <div :style="column.nameStyle">
              {{ column.name }}
            </div>
          </div>
          <div class="fill-row3 text-left">
          </div>
          <div class="fill-row3 text-right">
            <div :style="column.keyStyle">
              {{ data[column.key] }}
            </div>
          </div>
        </div>
        <div class="fill-row flex-row  margin-top-10" style="height:20px;line-height:20px;" v-if="column.type=='name_timekey'">
          <div class="fill-row3 text-left margin-left-10">
            <div :style="column.nameStyle">
              {{ column.name }}
            </div>
          </div>
          <div class="fill-row3 text-left">
            <div :style="column.keyStyle">
              {{formatDate(data[column.key], "yyyy-MM-dd") }}
            </div>
          </div>
          <div class="fill-row3 text-right margin-right-10">
          </div>
          <div>
          </div>
        </div>
        <div class="fill-row flex-row  margin-top-10" style="height:20px;line-height:20px;" v-if="column.type=='key_righttime'">
          <div class="fill-row3 text-left margin-left-10">
            <div :style="column.keyStyle">
              {{ data[column.key] }}
            </div>
          </div>
          <div class="fill-row3 text-left">

          </div>
          <div class="fill-row3 text-right margin-right-10">
            <div :style="column.rightkeyStyle">
              {{formatDate(data[column.rightkeytime], "yyyy-MM-dd") }}
            </div>
          </div>
          <div>
          </div>
        </div>
        <div class="fill-row flex-row  margin-top-10" style="height:20px;line-height:20px;" v-if="column.type=='key'">
          <div class="fill-row3 text-left margin-left-10">
            <div :style="column.keyStyle">
              {{ data[column.key] }}
            </div>
          </div>
          <div class="fill-row3 text-left">

          </div>
          <div class="fill-row3 text-right margin-right-10">

          </div>
          <div>
          </div>
        </div>
        <div class="fill-row flex-row margin-top-10" style="height:20px;line-height:20px;" v-if="column.type=='money_rightkey'">
          <div class="fill-row3 text-left margin-left-10">
            <div :style="column.moneyStyle">
              ￥{{ formatMoney(data[column.moneykey]) }}
            </div>
          </div>
          <div class="fill-row3 text-left">

          </div>
          <div class="fill-row3 text-right margin-right-10">
            <div :style="column.rightkeyStyle">
              {{ data[column.rightkey] }}
            </div>
          </div>
          <div>
          </div>
        </div>
        <div class="fill-row flex-row margin-top-10" style="heigth:30px;line-height:30px;" v-if="column.type=='button3'">
          <div class="fill-row3 grey-bg-color" style="border-bottom-left-radius: 10px;" @click="button1_click(data)">
            {{column.buttonname1}}
          </div>
          <div class="fill-row3 aaa-bg-color" @click="button2_click(data)">
            {{column.buttonname2}}
          </div>
          <div class="fill-row3 grey-bg-color" style="border-bottom-left-radius:20px;" @click="button3_click(data)">
            {{column.buttonname3}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, formatMoney } from "@/utils/format.js";
export default {
  name: "order",
  props: {
    datas: null,
    columns: null,
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    click(item) {
      this.$emit("click", item);
    },
    button1_click(item) {
      this.$emit("button1_click", item);
    },
    button2_click(item) {
      this.$emit("button2_click", item);
    },
    button3_click(item) {
      this.$emit("button3_click", item);
    },
  },
  data: () => {
    return {};
  },
};
</script>